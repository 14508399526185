#dock-container {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: fit-content;
    background: rgba(255,255,255,0.2);
    border-radius: 10px 10px 0 0;
}

#dock-container ul{
    padding: 0 2rem 0 2rem;
}

#dock-container li {
    list-style-type: none;
    display: inline-block;
    position: relative;
}

#dock-container li img {
    width: 50px;
    height: 50px;
    -webkit-box-reflect: below 2px
              -webkit-gradient(linear, left top, left bottom, from(transparent),
              color-stop(0.7, transparent), to(rgba(255,255,255,.5)));
    -webkit-transition: all 0.3s;
    -webkit-transform-origin: 50% 100%;
    margin: 0 0.5rem 0 0.5rem;
  }

  #dock-container li:hover img { 
    -webkit-transform: scale(1.2);
    margin: 0 1em;
  }
  #dock-container li:hover + li img,
  #dock-container li.prev img {
    -webkit-transform: scale(1.1);
    margin: 0 0.5em;
  }

  #dock-container li span {
    display: none;
    position: absolute;
    bottom: 80px;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.75);
    padding: 4px 0;
    border-radius: 12px;
}
#dock-container li:hover span {
    display: block;
    color: #fff;
}